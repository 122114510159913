.metabi-base-wrapper {
  padding: 8px 14px;
  background-color: #195c9d;
  color: #a8ebd6;
}

.metabi-room-wrapper {
  padding: 8px 14px;
  background-color: #22769e;
  color: #a8ebd6;
}

.metabi-package-wrapper {
  padding: 8px 14px;
  background-color: #2d939f;
  color: #002e5c;
}

.metabi-asset-wrapper {
  padding: 8px 14px;
  background-color: #37aea1;
  color: #002e5c;
}

.metabi-instance-wrapper {
  padding: 8px 14px;
  background-color: #43cda2;
  color: #002e5c;
}

.metabi-float-input {
  width: 40px;
  font-size: 14px;
}

.metabi-small-input {
  width: 30px;
  font-size: 14px;
}

.metabi-text-input {
  font-size: 14px;
}

.metabi-text-area-input {
  font-size: 14px;
  width: 270px;
  height: 100px;
  word-break: break-all;
}

.metabi-save-button {
  width: 80px;
}

.metabi-delete-button {
  width: 270px;
}

.metabi-close-button {
  position: absolute;
  right: 15px;
  top: 15px;
}

.metabi-copy-activity-button {
  color: #002e5c;
}

.metabi-input-label {
  position: absolute;
  font-size: 14px;
  left: 10%;
}

//////////////////////////////////////////////////////////////

.metabi-context-menu {
  border: 5px;
}

.metabi-context-menu-button {
  color: ghostwhite !important;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 15px;
  background: #007AB8;
  font-size: 20px;
}

.metabi-context-menu-title {
  font-size: 40px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: lightgray;
}

.metabi-context-menu-container-extra-long {
  position: absolute;
  width: 20%;
  left: 40%;
  top: 5%;
}

.metabi-context-menu-container-long {
  position: absolute;
  width: 20%;
  left: 40%;
  top: 13%;
}

.metabi-context-menu-container-medium {
  position: absolute;
  width: 20%;
  left: 40%;
  top: 25%;
}

.metabi-context-menu-container-short {
  position: absolute;
  width: 20%;
  left: 40%;
  top: 34%;
}

.metabi-context-menu-container-extra-short {
  position: absolute;
  width: 20%;
  left: 40%;
  top: 43%;
}

.metabi-lc-leave-button {
  position: absolute;
  left: 5%;
  top: 5%;
  padding: 20px 20px;
}

////////////////////////////////////////////////////////////////////

.metabi-gpt-feedback-container {
  position: absolute;
  width: 40%;
  left: 5%;
  top: 15%;
  font-size: 18px;
  justify-content: center;
  text-align: center;
  color: #000000;
  font-size: x-large;
  background-color: #9fdaec;
}

.metabi-npc-speech-container {
  position: absolute;
  width: 40%;
  left: 55%;
  top: 30%;
  font-size: 18px;
  justify-content: center;
  text-align: center;
  color: #000000;
  font-size: x-large;
  background-color: #9feca9;
}

.metabi-speech-to-text-container {
  position: absolute;
  width: 70%;
  left: 15%;
  top: 88%;
  font-size: 18px;
  justify-content: center;
  text-align: center;
  color: #000000;
  font-size: x-large;
  background-color: #ece49f;
}

/////////////////////

.metabi-objectives-chat-container-sidebar {
  display: flex;
  flex-direction: column;
  padding: 6px 8px;
  position: absolute;
  left: 65%;
  top: 2%;
  width: 33%;
  height: 97%;
  background-color: #5c5e5e90; //B0;
  border-radius: 16px;
}

.metabi-objectives-chat-container-bottombar {
  @extend .metabi-objectives-chat-container-sidebar;
  left: 2%;
  top: 66%;
  width: 96%;
  height: 33%;
}

.metabi-objectives-chat-container-fullscreen {
  @extend .metabi-objectives-chat-container-sidebar;
  left: 2%;
  top: 2%;
  width: 96%;
  height: 96%;
}

///////////////////////////

.metabi-objectives-chat-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.item, .left, .center, .right  {
  flex: 1;
  text-align: center;
}

.left {
  align-self: flex-start;
}

.center {
  align-self: center;
}

.right {
  align-self: flex-end;
}

.metabi-objectives-header-button {
  color: ghostwhite;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 10px;
  background: #6e6f70;
  font-size: 20px;  
  flex-shrink: 1;
  min-width: 0; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.metabi-objectives-header-button-active {
  @extend .metabi-objectives-header-button;
  background-color: #00a6ed;
}

.metabi-objectives-header-close-button {
  @extend .metabi-objectives-header-button;
  background-color: #aa0000;
}

////////////////////////////////////

.metabi-objectives-chat-header {
  top: 2%;
  left: 2%;
  background-color: #d4d3d3d5;
  border-radius: 16px;
  font-size: 14px;
  color: #070707;
  border-radius: 2px;
  padding-top: 2px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

/*
  text-shadow:
    -1px -1px 1px #efede3,
    0px 1px 0 #2e2e2e,
    0px 2px 0 #2c2c2c,
    0px 3px 0 #2a2a2a,
    0px 4px 0 #282828,
    0px 5px 0 #262626,
    0px 6px 0 #242424,
    0px 7px 0 #222,
    0px 8px 0 #202020,
    0px 9px 0 #1e1e1e,
    2px 8px 5px rgba(0, 0, 0, 0.9),
    5px 13px 5px rgba(0, 0, 0, 0.3),
    8px 17px 8px rgba(0, 0, 0, 0.5),
    8px 18px 35px rgba(0, 0, 0, 0.9);
*/

//.metabi-objectives-chat-objectives {
//  @extend .metabi-objectives-chat-header;
//  width: 100%;
//}

.metabi-objectives-chat-help {
  @extend .metabi-objectives-chat-header;
  position: absolute;
  top: 2%;
  left: 70%;
}

.metabi-objectives-close-button {
  position: absolute;
  font-size: 24px;
  left: 90%;
  top: 1%;
}

.metabi-objectives-chat-objectives {
  background-color: #d4d3d3d5;
  color: #070707;
  border-radius: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  //max-height: 90%;//30%;
  overflow-y: auto;
}

.metabi-objectives-lone-objective {
  background-color: #d4d3d3d5;
  color: #072370;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
}

.metabi-objectives-chat-panel {
  flex-grow: 1;
  overflow-y: auto;
  border-radius: 16px;
  background-color: #bbbbbb3d;
}

.metabi-objectives-help {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #5c5e5eb0;
  border-radius: 16px;
  margin-top: 4px;
}

.metabi-objectives-help-label {
  display: block;
  text-align: center;
  color: #edf1f3e7;
  width: 100%;
}

.metabi-objectives-help-input-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 8px;
}

.metabi-objectives-help-input {
  flex: 1;
  color: #efefef;
  background-color: #bbbbbb44;
  width: 90%;
  min-height: 45px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 6px;
  margin-top: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.metabi-objectives-mic-icon {
  width: 100%;
  height: 100%;
  //transform: rotate(-45deg);
}

.metabi-objectives-mic-button {
  flex: 0 0 auto;
  width: 45px;
  height: 45px;
  //transform: rotate(45deg);
  border-radius: 20%;
  background-color: #dd0000;
  margin-top: 4px;
  margin-right: 4px;
}

.metabi-objectives-send-button {
  @extend .metabi-objectives-mic-button;
  background-color: #00dd00;
  margin-left: 4px;
  margin-right: 0px;
  padding: 4px;
}



/////////////////////

.metabi-user-input-container {
  padding: 6px 8px;
  position: absolute;
  left: 2%;
  top: 84%;
  width: 65.5%;
  height: 15%;
  background-color: #5c5e5e70;
  border-radius: 16px;
}

.metabi-user-input-mic-button {
  position: absolute;
  left: 3%;
  top: 15%;
  padding: 6px 8px;
  width: 70px;
  height: 70px;
  //transform: rotate(45deg);
  border-radius: 50%;
  background-color: #dd0000;
}

.metabi-user-input-mic-timer {
  position: absolute;
  left: 3%;
  top: 75%;
  font-size: 24px;
  color: #efefef;
}

.mic-button-slow-transition {
  transition: background-color 1.5s step-end;
  //transform: rotate(45deg);
  animation: l18 1.5s linear;
}

.mic-button-instant-transition {
  transition: none;
}

@keyframes l18 { 
  0% { clip-path: polygon(50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%); } 
  25% { clip-path: polygon(50% 0%, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%); } 
  50% { clip-path: polygon(50% 0%, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%); } 
  75% { clip-path: polygon(50% 0%, 50% 0%, 100% 0%, 100% 100%, 0 100%, 0 100%); } 
  100% { clip-path: polygon(50% 0%, 50% 0%, 100% 0%, 100% 100%, 0 100%, 0 0%); }
 }

/*
@keyframes l18 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
  }
}*/

.metabi-user-input-mic-icon {
  width: 100%;
  height: 100%;
  transform: rotate(-45deg);
}

.metabi-user-input-text {
  font-size: 22px;
  position: absolute;
  left: 13%;
  top: 10%;
  width: 86%;
  color: #dddddd;
  background-color: #38434e71;
}


.metabi-user-input-audio-input {
  position: absolute;
  left: 25%;
  top: 45%;
}

.metabi-user-input-chat-button {
  position: absolute;
  left: 38%;
  top: 45%;
  color: #dddddd;
}

.metabi-user-input-chart-button {
  position: absolute;
  left: 50%;
  top: 45%;
  color: #dddddd;
}

.metabi-user-input-form-button {
  position: absolute;
  left: 50%;
  top: 45%;
  color: #dddddd;
}

.metabi-user-input-tasks-button {
  position: absolute;
  left: 58%;
  top: 45%;
  color: #dddddd;
}

.metabi-user-input-log-button {
  position: absolute;
  left: 66%;
  top: 45%;
}

.metabi-user-input-gpt-button {
  position: absolute;
  left: 66%;
  top: 45%;
}

.metabi-user-input-db-button {
  position: absolute;
  left: 74%;
  top: 45%;
  color: #dddddd;
}

.metabi-user-input-leave-button {
  position: absolute;
  left: 84%;
  top: 45%;
}

.metabi-user-input-more-button {
  position: absolute;
  left: 92%;
  top: 45%;
  color: #dddddd;
}

.metabi-more-button-container {
  position: absolute;
  left: 2%;
  top: 2%;
  width: 45px;
  height: 45px;
  background-color: #5c5e5ed0;
}

.metabi-more-button {
  border-radius: 20%;
  position: absolute;
  left: 2%;
  top: 2%;
  width: 45px;
  height: 45px;
  background-color: #bbbbbbe0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-menu-container {
  border-radius: 16px;
  position: absolute;
  left: 2%;
  top: 10%;
  min-height: 60px; /* Set a minimum height */
  max-height: 400px; /* Optionally, set a maximum height */
  overflow-y: auto; /* Add vertical scrollbar if content exceeds max-height */
  background-color: #bbbbbb56;
}

.metabi-more-menu-header {
  padding-top: 5px;
  border-radius: 16px;
  background-color: #bbbbbb85;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.metabi-more-menu {
  padding-top: 10px;
  border-radius: 16px;
  background-color: #bbbbbb67;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.metabi-more-menu-item {
  background-color: #bbbbbb8e;
  border-width: 0px;
  width: 100%;
  display: flex;
  font-size: 16px;
  margin-top: 5px;
}

.metabi-more-menu-icon {
  width: 30px;
  margin-right: 10px;
}

.metabi-more-menu-text {
  font-size: 18px;
  margin-right: 10px;
}
//////////////

.metabi-user-input-container-mobile {
  padding: 6px 8px;
  position: absolute;
  width: 85%;
  top: 80%;
  height: 18%;
  background-color: #5c5e5ed0;
}

//.metabi-user-input-lipsync {
//  position: absolute;
//  left: 90%;
//}

//.metabi-user-input-lipsync-label {
//  position: absolute;
//  left: 92%;
//  color: #002e5c;
//}

//////////////////////////////////////////

.gizmo-toolbar-container {
  padding: 8px 14px;
  position: absolute;
  width: 5%;
  height: 10%;
  top: 5%;
  left: 5%;
  background-color: #ffffff80;
}

.metabi-gpt-dropdowns {
  font-size: 14px;
}

.metabi-group-dropdown {
  font-size: 14px;
  color: #002e5c;
}

//////////////////////////////////////

.metabi-gpt-input-container {
  padding: 8px 14px;
  position: absolute;
  width: 80%;
  height: 92%;
  left: 3%;
  top: 5%;
  font-size: 18px;
  justify-content: left;
  text-align: left;
  color: #ffffff;
  font-size: x-large;
  background-color: #6f7070B0;
  resize: both;
}

.metabi-gpt-input-dropdown {
  font-size: 14px;
  color: #002e5c;
}

.metabi-gpt-input-model-dropdown {
  font-size: 14px;
  color: #002e5c;
  position: absolute;
  left: 20%;
}

.metabi-gpt-task-npc-dropdown {
  position: absolute;
  color: #002e5c;
  font-size: 14px;
  left: 20%;
}

.metabi-gpt-task-global-score-label {
  position: absolute;
  left: 50%;
  width: 10%;
}

.metabi-gpt-task-global-score {
  position: absolute;
  font-size: 14px;
  left: 60%;
  width: 7%;
  color: #002e5c;
}

.metabi-gpt-input-checkbox {
  position: absolute;
  left: 20%;
}

.metabi-gpt-input-text {
  font-size: 14px;
  position: absolute;
  left: 20%;
  width: 75%;
  color: #002e5c;
}


.metabi-gpt-responder-dropdown {
  position: absolute;
  color: #002e5c;
  font-size: 14px;
  left: 20%;
}

.metabi-gpt-input-text-blob {
  font-size: 14px;
  position: absolute;
  left: 30%;
  width: 65%;
  height: 50%;
  color: #002e5c;
}

.metabi-gpt-input-text-blob-45 {
  @extend .metabi-gpt-input-text-blob;
  height: 45%;
}

.metabi-gpt-input-text-blob-40 {
  @extend .metabi-gpt-input-text-blob;
  height: 40%;
}

.metabi-gpt-input-text-blob-medium {
  @extend .metabi-gpt-input-text-blob;
  height: 35%;
}

.metabi-gpt-input-text-blob-30 {
  @extend .metabi-gpt-input-text-blob;
  height: 30%;
}

.metabi-gpt-input-text-blob-25 {
  @extend .metabi-gpt-input-text-blob;
  height: 25%;
}

.metabi-gpt-input-text-blob-small {
  @extend .metabi-gpt-input-text-blob;
  height: 20%;
}

.metabi-gpt-input-text-blob-smaller {
  @extend .metabi-gpt-input-text-blob;
  height: 8%;
}

.metabi-gpt-input-numeric {
  font-size: 14px;
  position: absolute;
  left: 20%;
  width: 7%;
  color: #002e5c;
}

.metabi-gpt-input-pronunciation-label {
  font-size: 12px;
  position: absolute;
  left: 86%;
  top: 1.5%;
  width: 10%;
}

.metabi-gpt-input-pronunciation-scores {
  font-size: 14px;
  position: absolute;
  left: 95%;
  top: 1.5%;
  width: 2%;
}

.metabi-gpt-input-task-num {
  font-size: 14px;
  position: absolute;
  left: 95%;
  top: 5%;
  width: 2.5%;
  color: #002e5c;
}

.metabi-gpt-button {
  width: 120px;
  font-size: 18px;
}

.metabi-gpt-delete-button {
  position: absolute;
  left: 85%;
  top: 9%;
  position: absolute;
  font-size: 18px;
}

.metabi-gpt-send-button {
  left: 86%;
  position: absolute;
  font-size: 18px;
}

.metabi-gpt-plus-button {
  width: 24px;
  font-size: 22px;
}

.metabi-gpt-wrapper {
  padding: 8px 14px;
  //background-color: #6f7070B0;
  //color: #ffffff;
  justify-content: left;
  text-align: left;
}

.metabi-current-task {
  //color: #00aa00;
  color: #072370;
  font-size: 14px;
  font-weight: bold;
}

.metabi-other-task {
  color: #080808;
  font-size: 14px;
}

.metabi-background {
  color: hsl(0, 0%, 0%);
  font-size: 18px;
}

.wrapper fieldset {
  margin: 20px 0;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

.metabi-text-center {
  height: 100px;
  text-align: center;
  vertical-align: center;
}

.metabi-button-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rpm-avatar-creator-wrapper {
  width: 1200px;
  padding: 8px 14px;
  border-radius: 16px;
  background-color: #007ab8;
  color: #ffffff;
}

.rpm-avatar-options-wrapper {
  width: 1000px;
  padding: 8px 14px;
  background-color: #007ab8;
  color: #ffffff;
}

.rpm-avatar-creator-frame {
  width: 1080px;
  height: 800px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  padding: 20px;
  font-size: 14px;
  border: none;
}

.red-anchor {
  color: red;
  font-size: xx-large;
}

.avatar-download {
  padding: 40px;
}

///////////////////////////////////////////////////////

//.metabi-gpt-prompt-name-label {
//  @extend .metabi-input-label;
//  top: 20%;
//}

.metabi-gpt-prompt-name {
  position: absolute;
  font-size: 14px;
  left: 12%;
  color: #072370;
  //width: 30%;
  //top: 20%;
}

//.metabi-gpt-prompt-sort-label {
  //@extend .metabi-input-label;
  //top: 20%;
  //left: 52%;
//}

.metabi-gpt-prompt-sort {
  position: absolute;
  font-size: 14px;
  left: 12%;
  //top: 20%;
}

//.metabi-gpt-prompt-model-label {
//  @extend .metabi-input-label;
//  top: 20%;
//  left: 65%;
//}

.metabi-gpt-prompt-model {
  position: absolute;
  font-size: 14px;
  color: #072370;
  left: 12%;
  //top: 20%;
}

//.metabi-gpt-prompt-temperature-label {
//  @extend .metabi-input-label;
//  top: 20%;
//  left: 83%;
//}

.metabi-gpt-prompt-temperature {
  position: absolute;
  font-size: 14px;
  left: 12%;
  //top: 20%;
}

//.metabi-gpt-driver-array-label {
//  @extend .metabi-input-label;
//  top: 25%;
//}

.metabi-gpt-prompt-driver {
  position: absolute;
  font-size: 14px;
  left: 12%;
  //top: 25%;
}

.metabi-gpt-prompt-response-codes {
  position: absolute;
  font-size: 14px;
  left: 12%;
  //top: 25%;
}

.metabi-gpt-prompt-data-label {
  @extend .metabi-input-label;
  position: absolute;
  top: 27%;
  left: 30%;
}

.metabi-gpt-prompt-data {
  @extend .metabi-gpt-input-text-blob-30;
  position: absolute;
  top: 30%;
  left: 30%;
}

.metabi-gpt-prompt-system-label {
  @extend .metabi-input-label;
  position: absolute;
  top: 62%;
  left: 30%;
}

.metabi-gpt-prompt-system {
  @extend .metabi-gpt-input-text-blob-30;
  position: absolute;
  top: 65%;
  left: 30%;
}

///////////////////////////////////////////////////////
.metabi-gpt-action-label {
  position: absolute;
  font-size: 14px;
  left: 10%;
}

.metabi-gpt-action-input {
  position: absolute;
  color: #000000;
  font-size: 14px;
  width: 65%;
  left: 25%;
}

.metabi-gpt-action-select-label {
  @extend .metabi-gpt-action-label;
  font-size: 18px;
  top: 25%;
}

.metabi-gpt-action-select {
  @extend .metabi-gpt-action-input;
  top: 25%;
}

.metabi-gpt-action-name-label {
  @extend .metabi-gpt-action-label;
  font-size: 18px;
  top: 30%;
}

.metabi-gpt-action-name {
  @extend .metabi-gpt-action-input;
  top: 30%;
}

.metabi-gpt-action-anim-label {
  @extend .metabi-gpt-action-label;
  top: 35%;
}

.metabi-gpt-action-anim {
  @extend .metabi-gpt-action-input;
  top: 35%;
}

.metabi-gpt-action-text-label {
  @extend .metabi-gpt-action-label;
  top: 50%;
}

.metabi-gpt-action-text {
  @extend .metabi-gpt-action-input;
  top: 50%;
}

.metabi-gpt-action-speech-synth {
  position: absolute;
  top: 74% !important; //WTF? Cannot get action here.
}

.metabi-gpt-action-tts-voice-label {
  @extend .metabi-gpt-action-label;
  top: 55%;
}

.metabi-gpt-action-tts-voice {
  @extend .metabi-gpt-action-input;
  top: 55%;
}

.metabi-gpt-action-sound-url-label {
  @extend .metabi-gpt-action-label;
  top: 60%;
}

.metabi-gpt-action-sound-url {
  @extend .metabi-gpt-action-input;
  top: 60%;
}

.metabi-gpt-action-audio-json-label {
  @extend .metabi-gpt-action-label;
  top: 65%;
}

.metabi-gpt-action-audio-json {
  @extend .metabi-gpt-action-input;
  top: 65%;
  height: 15%;
}

.metabi-gpt-add-action-button {
  width: 24px;
  font-size: 20px;
  position: absolute;
  left: 90%;
}

.metabi-gpt-delete-action-button {
  width: 24px;
  font-size: 20px;
  position: absolute;
  left: 93%;
}

///////////////////////////////////////////////////////

.query-view-main {
  position: absolute;
  top: 10%;
  left: 38%;
  width: 60%;
  height: 85%;
  border: 1px solid #000000;
  background-color: #daddddd0;
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  text-shadow: none;
  overflow: scroll;
}

.query-view-select {
  position: absolute;
  width: 20%;
  left: 12%;
  color: #000000;
  font-size: 16px;
}

.query-view-select-label {
  position: absolute;
  width: 25%;
  left: 2%;
  color: #ffffff;
  font-size: 20px;
}


.query-view-select-type-label {
  @extend .query-view-select-label;
  top: 10%;
}

.query-view-select-type {
  @extend .query-view-select;
  top: 10%;
}

.query-view-order-descending {
  @extend .query-view-select;
  top: 15%;
}

.query-view-select-user-label {
  @extend .query-view-select-label;
  top: 20%;
}

.query-view-select-user {
  @extend .query-view-select;
  top: 20%;
}

.query-view-select-group-label {
  @extend .query-view-select-label;
  top: 25%;
}

.query-view-select-group {
  @extend .query-view-select;
  top: 25%;
}

.query-view-select-time-span-label {
  @extend .query-view-select-label;
  top: 30%;
}

.query-view-select-time-span {
  @extend .query-view-select;
  top: 30%;
}

.query-view-start-time-label {
  @extend .query-view-select-label;
  top: 30%;
}

.query-view-start-time {
  @extend .query-view-select;
  top: 30%;
  left: 9%;
}

.query-view-end-time-label {
  @extend .query-view-select-label;
  top: 35%;
}

.query-view-end-time {
  @extend .query-view-select;
  top: 35%;
  left: 9%;
}

.query-view-refresh-button {
  @extend .query-view-select;
  top: 75%;
  height: 5%;
}

.query-view-column {
  position: absolute;
}

.query-view-column-1 {
  @extend .query-view-column;
  left: 5%;
}

.query-view-column-2 {
  @extend .query-view-column;
  left: 45%;
}

.query-view-column-3 {
  @extend .query-view-column;
  left: 55%;
}

.query-view-column-4 {
  @extend .query-view-column;
  left: 70%;
}

.query-view-column-5 {
  @extend .query-view-column;
  left: 85%;
}

/////////////////////////////////////////////
///

//////////////////////////////////
///

.metabi-gpt-make-sound-button {
  width: 140px;
  font-size: 16px;
  position: absolute;
  left: 23%;
}

.metabi-gpt-response-voice-select {
  color: "#002e5c";
  width: 180px;
  font-size: 16px;
  position: absolute;
  left: 38%;
}

.metabi-gpt-response-style-select {
  color: "#002e5c";
  width: 180px;
  font-size: 16px;
  position: absolute;
  left: 56%;
}

.metabi-gpt-response-rate-label {
  width: 40px;
  font-size: 16px;
  position: absolute;
  left: 75%;
}

.metabi-gpt-response-rate-input {
  width: 40px;
  font-size: 16px;
  position: absolute;
  left: 77%;
}

.metabi-gpt-response-volume-label {
  width: 40px;
  font-size: 16px;
  position: absolute;
  left: 82%;
}

.metabi-gpt-response-volume-input {
  width: 40px;
  font-size: 16px;
  position: absolute;
  left: 84%;
}

.metabi-gpt-response-pitch-label {
  width: 40px;
  font-size: 16px;
  position: absolute;
  left: 89%;
}

.metabi-gpt-response-pitch-input {
  width: 40px;
  font-size: 16px;
  position: absolute;
  left: 91%;
}

.metabi-gpt-make-lipsync-button {
  width: 150px;
  font-size: 16px;
  position: absolute;
  left: 82%;
}

///////////////////////////

.metabi-objectives-chat-settings {
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #edf1f3e7;
  width: 100%;
}

.horizontal-slider {
  width: 90%;
  height: 25px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.thumb {
  height: 25px;
  width: 25px;
  background-color: #000;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-25%);
}

.track {
  top: 0;
  bottom: 0;
  background: #a5a5a5;
  border-radius: 999px;
}

.mark {
  width: 10px;
  height: 10px;
  background-color: #1ba100;
  border-radius: 50%;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.mark-label {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  font-size: 12px;
  top: 35px; /* Adjust based on spacing needs */
}